import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import { callbackify } from 'util';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles';

interface Props {
  text: string;
  Type: string;
  AriaLabel: string;
  color?: string;
  colorBackground?: string;
  colorHover?: string;
  colorBackgroundHover?: string;
  colorBorder?: string;
  colorBorderHover?: string;
  width?: string;
  widthDesk?: string;
  disableIt?: boolean;
  callback?: any;
  lefty?: boolean;
}

export default function Button(props: Props) {
  const {
    text,
    Type,
    AriaLabel,
    color,
    colorBackground,
    colorHover,
    colorBackgroundHover,
    colorBorder,
    width,
    widthDesk,
    disableIt,
    callback,
    lefty,
    colorBorderHover,
  } = props;

  return (
    <Wrapper
      type={Type}
      aria-label={AriaLabel}
      color={color}
      colorborder={colorBorder}
      colorhover={colorHover}
      colorbackground={colorBackground}
      colorbackgroundhover={colorBackgroundHover}
      width={width}
      widthdesk={widthDesk}
      disabled={disableIt}
      disableit={`${disableIt}`}
      onClick={() => (typeof callback === 'function' ? callback() : null)}
      lefty={lefty}
    >
      <p>{text}</p>
    </Wrapper>
  );
}

const Wrapper = styled(animated.button)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ lefty }) => (lefty ? 'flex-start' : 'center')};
  width: ${({ width }) => width || '70%'};
  padding: ${({ lefty }) => (lefty ? `${mobileVW(29)} ${mobileVW(20)}` : `${mobileVW(29)} 0`)};
  ${({ color }) => (color ? `color: ${color};` : null)}
   background-color: ${props => props.colorbackground || 'transparent'};
  border:1px solid ${props => props.colorBorder || props.color}; 
  //opacity: ${({ disableit }) => (disableit === 'true' ? 0.5 : 1)};
  pointer-events: ${({ disableit }) => (disableit === 'true' ? 'none' : 'auto')};
  box-sizing: content-box;
  overflow: hidden;
  transition-duration: 0.25s;

  &:hover{
    ${desktopBreakpoint}{
      background-color: ${props => props.colorbackgroundhover || props.colorbackground}; 
      border-color: ${props => props.colorborder || '#000'};
      border:1px solid ${props => props.colorBorder || props.color}; 
      p{
        color: ${props => props.colorHover || '#fff'};
      }
    }
    
  }


    p{
      font-size: ${mobileVW(18)};
      font-family: 'messinaMonoLight', "Courier New", Courier, monospace;
      color: ${({ color }) => color};
      pointer-events: none;
      z-index: 1;
    }

  ${desktopBreakpoint}{
    //padding: ${({ widthdesk }) => (widthdesk ? 0 : `${desktopVW(2)} ${desktopVW(50)}`)};
    padding: ${desktopVW(28)} 0;
    width: ${({ widthdesk }) => widthdesk || 'auto'};
    cursor: pointer;
    justify-content: center;
    p{
      font-size: ${desktopVW(15)};
    }
  }
`;

const Layer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${colors.greenLight};
  pointer-events: none;
  transform-origin: center left;
`;
